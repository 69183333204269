$color-goldenrod: #ffc809;
$color-dark: #2b2e42;
$color-slate-grey: #535567;
$color-blue-grey: #8b99ae;
$color-pale-blue: #e3e8e9;
$color-powder-blue: #d1d6df;
$color-cherry-red: #ff0026;
$color-pale-gold: #ffde6c;
$color-steel: #80828e;
$color-off-white: #eef5f7;
$color-white: #fff;


$secondary: #2b2e42;
$secondary100: #535567;
$secondary200: #80828e;
$secondary400: #8b99ae;
$secondary500: #c4ccd9;
$secondary600: #d1d6df;
$secondary700: #e3e8e9;
$secondary900: #ffffff;
$primary: #ffc809;
$primary300: #ffde6c;
$primary400: #ffe382;
$bgtint: #c2e6ff;
$bgtint100: #eef5f7;
$bgtint500: #dff8ff;
$bgtint900: #72aed7;
$errortint: #ff0226;

