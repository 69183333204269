@import "finzmoBase";

.navbar-burger {
  span {
    background-color: #2b2e42;
  }
}

.parallax-container {
  max-width: 1400px;
  margin: 0 auto;

  @media only screen and (min-width: 1024px) and (max-width: 1480px) {
    padding: 0 2rem;
  }
}

.parallax-product-blocks {
  @media only screen and (min-width: 768px) {
    min-height: 659px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1480px) {
    margin: 0 2rem;
  }

  @media only screen and (min-width: 1480px) {
    max-width: 1400px;
    margin: 0 auto;
  }

  .html-desktop-background {
    position: absolute;
    height: 100%;

    @media only screen and (min-width: 768px) {
      margin-top: -20px;
      padding-top: 20px;
      background-size: contain;
      background-position-x: 0;
    }
  }

  .html-mobile-background {
    margin-top: -40px;
    padding-top: 40px;
    position: absolute;
    height: 100%;
    background-position: top;

    @media only screen and (min-width: 768px) {
      margin-top: -20px;
      padding-top: 20px;
      background-size: contain;
      background-position-x: 0;
    }
  }

  .product-blocks {
    box-shadow: 0 20px 35px 5px rgba(83, 85, 103, 0.5);

    .product-block {
      .product-header {
        display: none;
      }
    }
  }

  .hero-text {
    margin-top: 120px;
    height: auto;

    @media only screen and (min-width: 768px) {
      text-align: right;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 43px;
    }

    h1 {
      color: #ffffff;
      text-shadow: 0 2px 6px #2b2e42;

      @media only screen and (min-width: 768px) {
        font-size: 40px;
        font-weight: 600;
        color: var(--fi-hero-text-color, #ffffff);
        text-shadow: none;
      }
    }
  }
}

.header-product-blocks {
  .hero-text {
    font-size: 48px;
  }
}

.product-blocks {
  padding-top: 0;

  .product-block {
    border: 0;
    display: none;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: #fff;

    &.active {
      display: block;
    }

    & > div:nth-child(1) {
      height: initial;
    }

    .product-panel {
      transition: none;

      p {
        margin: 14px 0;
      }
    }
  }
}

.product-apr-container {

  .product-apr-block {

    .product-selection-apr {
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin: 10px;

      @media only screen and (min-width: 768px) {
        bottom: 60px;
      }

      p {
        &:before {
          left: 0;
          top: 0;
        }
      }
    }

  }

}

.products-selection-tabs {
  display: flex;
  background: #fff;

  .products-selection-tab {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    color: $secondary900;
    font-size: 0.9rem;
    background-color: $color-dark;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $color-dark;
      font-weight: bold;
      background-color: $secondary900;
    }

    &:nth-child(1) {
      padding-left: 24px;
    }

    &:last-child {
      flex: 1;
    }

    @media only screen and (min-width: 992px) {
      width: auto;
      white-space: nowrap;

      &:last-child {
        padding-right: 24px;
      }
    }
  }
}


.menu-list {
  li a {
    color: $color-dark;
  }
}

.header-container {
  border-bottom: 0;
}

.testimonials-container {
  .testimonials {
    align-items: flex-start;
    border: 0;
    background: none;

    .testimonial {
      display: flex;
      flex-direction: column-reverse;
      border-right: 0;

      .testimonial-author {
        color: #fff;
      }

      &-heading {
        margin-top: 24px;
        margin-bottom: 0;
      }

      &-image {
        img {
          border-radius: 0;
        }
      }

      &-text {
        color: #fff;
      }
    }
  }
}

.how-does-it-work {
  .how-does-it-work-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .how-does-it-work-text {
      font-size: 24px;
      text-align: center;
      margin-top: 28px;
    }

    .how-does-it-work-picture {
      img {
        width: 240px;
      }
    }
  }
}

#advantages-container {
  h2 {
    color: #fff;
    text-align: center;
    padding-bottom: 80px;
  }

  .advantages {
    .advantage {
      .advantage-index {
        display: flex;
        width: 30px;
        height: 31px;
        min-width: 30px;
        min-height: 31px;

        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #ffc809;

        border: solid 5px #ffc809;
        margin-right: 20px;
      }
    }
  }
}

.mobile {
  .product-blocks {
    box-shadow: none;
  }
}
