@import "../colors";

:root {
  --fi-primary: #{$primary};
  --fi-primary-300: #{$primary300};
  --fi-secondary: #{$secondary};
  --fi-secondary-900: #{$primary};
  --fi-secondary-700: #{$secondary700};
  --fi-secondary-600: #{$secondary600};
  --fi-secondary-500: #{$secondary500};
  --fi-secondary-400: #{$secondary400};
  --fi-secondary-200: #{$secondary200};
  --fi-secondary-100: #{$secondary100};
  --fi-error: #{$errortint};
  --fi-button-width: 335px;
  --fi-button-margin: 10px;
  --fi-button-border-radius: 0;
  --fi-blog-product-bg: #{$secondary900};

  --fi-slider-value-font-size: 36px;
  --fi-slider-thumb-border-radius: 0;
  --fi-slider-control-border-radius: 0;

  --fi-footer-bg-color: #1a1c2b;
  --fi-footer-text-highlight: #{$color-white};
  --fi-footer-text-color: #{$color-steel};
  --fi-hero-text-color: #{$secondary};

  --fi-well-facts-bg: #ffc80a;

  --fi-well-how-it-works-bg: #ffc80a;

  --fi-well-partners-bg: #2b2e42;
  --fi-well-partners-text-color: #fff;

  --fi-well-testimonials-bg: #2b2e42;
  --fi-well-testimonials-text-color: #fff;

  --fi-well-advantages-bg: #2b2e42;
  --fi-well-advantages-text-color: #fff;
}

@media only screen and (max-width: 576px) {
  :root {
    --fi-button-width: 100%
  }
}
